@import "./node_modules/bulma/sass/utilities/_all";
@import "./node_modules/bulma/sass/base/_all";
@import "./node_modules/bulma/sass/grid/_all";
@import "./node_modules/bulma/sass/layout/_all";
@import "./node_modules/bulma/sass/elements/_all";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,600");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  letter-spacing: 0.8px;
  background: #ffffff;
  color: #6d6d6d;
  font-size: 14px;
}

.bgc-gray {
  background-color: #f9f9f9;
}

#menu-resv {
  width: 100%;
  background-color: #4481b6;
}

#nav-icon {
  display: none;
}

.title {
  margin-bottom: 30px;
  font-size: 2em;
  letter-spacing: 3px;
  color: #57c5f3;
  font-weight: 300;
}

.tagline {
  h2 {
    color: #fff;
    font-size: 3em;
    text-shadow: 0px 0px 2px #585858, 0 0 3px #e3e3e3;
    letter-spacing: 3px;
    font-weight: 300;
    text-align: center;
  }
}

.header-background {
  // transition-duration: 0.5s;
  background-color: rgba(0, 0, 0, 0.8) !important;
  box-shadow: 0px 2px 10px 0px #2982bf !important;
  height: 80px;
}

header {
  transition-duration: 0.5s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;

  #logo {
    display: block;
    position: absolute;
    top: 15px;
    img {
      width: 180px;
    }
  }

  .mainmenu {
    margin: 0;
    float: right;
  }

  nav {
    ul {
      display: flex;
      li {
        display: inline-block;
        text-decoration: none;

        a {
          transition-duration: 0.5s;
          box-sizing: border-box;
          color: #fff !important;
          font-weight: 800;
          padding: 30px 15px;
          display: block;
          text-decoration: none !important;
          letter-spacing: 2px;

          &:hover {
            color: #57c5f3;
            text-decoration: none;
          }

          &:focus {
            color: #47aeff !important;
            text-decoration: none;
          }
        }
      }
    }
  }
}

#hero-image {
  background: url("../img/bg.jpg") no-repeat center center fixed;

  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-attachment: scroll;
  width: auto !important;
  max-width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

#about,
#service,
#team,
#contact {
  text-align: center;
}

.service-item {
  svg {
    transition-duration: 0.5s;
    fill: #737373;
    .cls-1,
    .cls-2,
    .cls3 {
      transition-duration: 0.5s;
      stroke: #737373;
    }
  }

  border-radius: 1em;
  transition-duration: 0.5s;
  text-align: center;
  border: 1px solid #e3e3e3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  margin: 0 auto;

  .fa {
    font-size: 1.2em;
  }
  h4 {
    font-size: 14px;
  }
  &:hover {
    background: #57c5f3;
    color: #fff;

    svg {
      pointer-events: none;
      fill: #fff;

      .cls-1,
      .cls-2,
      .cls3 {
        transition-duration: 0.5s;
        stroke: #fff;
      }
    }
  }

  img {
    max-width: 40px;
    align-self: center;
  }
}

footer {
  background: #272b31;
  padding: 15px 0;
  border-bottom: 10px solid #57c5f3;
  p {
    margin: 0;
  }

  #copyright {
    text-align: left;
    position: absolute;
    display: flex;
    line-height: 3;
  }

  .go-top {
    text-align: right;
    position: relative;
    top: 10px;
  }
}

@media only screen and (max-width: 720px) {
  header {
    nav ul {
      display: block;
    }
    #logo {
      position: absolute;
      top: 15px;
      left: 15px;
      img {
        width: 140px;
      }
    }
  }

  #overlay {
    transition: 0.25s ease-in-out;
    background: #272727;
    position: fixed;
    width: 100% !important;
    height: 0;
    overflow: auto;
  }

  .overlay-open {
    height: 100% !important;
  }

  ul.mainmenu {
    float: none;
    // padding: 0 !important;
    margin-top: 5em;

    li {
      display: block;
      text-align: center;

      a {
        letter-spacing: 3px;
        font-weight: 300 !important;
        color: #e3e3e3 !important;
        font-size: 2em !important;
        padding: 5px;

        &:hover {
          color: #57c5f3 !important;
        }
      }
    }
  }

  #hero-image {
    height: 300px;
    .tagline {
      h2 {
        font-size: 1.7em;
        padding: 10px;
      }
    }
  }

  .service-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    // height: 70px;
    h4 {
      font-size: 12px;
      margin: 5px 0;
    }

    .fa {
      font-size: 1em;
    }
  }

  #nav-icon {
    display: block;
    position: fixed;
    right: 20px;
    top: 20px;
    width: 40px;
    height: 40px;
    z-index: 9999;
    cursor: pointer;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;

    span {
      position: absolute;
      display: block;
      width: 100%;
      height: 5px;
      background: #fff;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
  }

  #nav-icon span:nth-child(1) {
    top: 0px;
  }

  #nav-icon span:nth-child(2) {
    top: 12px;
  }

  #nav-icon span:nth-child(3) {
    top: 24px;
  }

  #nav-icon.animate-icon span:nth-child(1) {
    top: 12px;
    transform: rotate(135deg);
  }

  #nav-icon.animate-icon span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }

  #nav-icon.animate-icon span:nth-child(3) {
    top: 12px;
    transform: rotate(-135deg);
  }
}
